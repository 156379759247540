import React from 'react'
import '../styles/animate.css';
import '../styles/bootstrap.css';
import '../styles/colors.css';
import '../styles/custom.css';
import '../styles/dark.css';
import '../styles/style.css';
import { connect } from 'react-redux';

class Topbar extends React.Component {
    render() {
        return (
            <div id="page-menu" data-mobile-sticky="true" className="">
			<div id="page-menu-wrap">
				<div class="container">
					<div class="page-menu-row">

						<div class="page-menu-title">Vind je onze site handig? Steun ons met een kleine bijdrage! 
							<span class="donate">
								<a target="_blank" href="https://payment-links.mollie.com/payment/bW96qJYXtGFszmwUzDS78" className="button button-rounded button-white button-light"><div>€ 2</div></a>
								<a target="_blank" href="https://payment-links.mollie.com/payment/myxQS4jfkqFH2KN9zbxQ8" className="button button-rounded button-white button-light"><div>€ 5</div></a>
								<a target="_blank" href="https://payment-links.mollie.com/payment/jxuTga5P3jJuNrR2XK25Z" className="button button-rounded button-white button-light"><div>€ 10</div></a>
							</span> 
						</div>

						{/* <nav class="page-menu-navCustom">
							<ul class="page-menu-containerCustom">
                                <div className='page-menu-pretext-custom'>Doneer eenmalig:</div>
								<li class="page-menu-itemCustom">
                                    <a target="_blank" href="https://payment-links.mollie.com/payment/bW96qJYXtGFszmwUzDS78" className="button button-rounded button-white button-light"><div>€ 2</div></a></li>
								<li class="page-menu-itemCustom">
                                    <a target="_blank" href="https://payment-links.mollie.com/payment/VnT6UqgZRE25oFgRAgr8b" className="button button-rounded button-white button-light"><div>€ 5</div></a></li>
								<li class="page-menu-itemCustom">
                                    <a target="_blank" href="https://payment-links.mollie.com/payment/5DAjmamEUFgUkspJZ6t5z" className="button button-rounded button-white button-light"><div>€10</div></a></li>
							</ul>
						</nav> */}
					</div>
				</div>
			</div><div class="page-menu-wrap-clone" style={{height: 48}}></div>
		</div>
        )
    }
}

function mapStateToProps() {   
    return {
        
    }
}

function mapDispatchToProps(dispatch) {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);